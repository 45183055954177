<template>
    <div class="h20"></div>
    <dl class=" bgf csi1">
        <dt class="col1582 font16 lh60 borbot marb10">绑定CSI号详情</dt>
        <dd >
            <span>公司CSI编号</span>
            <span class="colzhuti font14">{{info.csiNumber}} </span>
        </dd>
        <dd >
            <span>公司名称</span>
            <span >{{info.companyName}} </span>
        </dd>
        <dd >
            <span>采购产品</span>
            <span >{{info.productName}}</span>
        </dd>
        <dd >
            <span>身份</span>
            <span >{{ ['','普通员工','CAU管理员'][info.identity]}} &nbsp;&nbsp; 

                <el-button @click="fenpei=true;accountId='';" v-if="info.identity==2 && info.status==2" size="mini" type="primary" round>
                    <div class="flex flexa">
                        <img :src="utils.loadImg('user1.png')" width="12" height="12" alt="" srcset=""> &nbsp;
                        分配CAU给其他人
                    </div>
                </el-button>
            </span>
        </dd>
        <dd >
            <span>状态</span>
            <span class="colblue">{{ ['','待审核','绑定成功','审核失败'][info.status]}} &nbsp;&nbsp;&nbsp;&nbsp;  
                <el-button @click="jiebang=true" size="mini" type="danger"  round>
                    <div class="flex flexa">
                        <img :src="utils.loadImg('cel.png')" width="14" alt="" srcset=""> &nbsp;
                        解除绑定
                    </div>
                </el-button>
            </span>
        </dd>
        
    </dl>
    <p class="h20"></p>
    <div class="pad20 bgf" v-if="info.identity==2">
        <p class="col1582 font16 lh60 borbot">人员管理</p>
        <div class="padt20 padb30">
            <el-table
            :data="info.personLists"
            :header-cell-style="{ background: '#F1F1F1',} "
            class="border"
            style="width: 100%">
                <el-table-column
                fixed
                prop="username"
                label="用户名"
                min-width="150">
                </el-table-column>
                <el-table-column
                prop="mobile"
                label="手机号"
                >
                </el-table-column>
                <el-table-column
                prop="accountId"
                label="ID账号"
                show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                prop="createTime"
                label="时间"
                min-width="100">
                </el-table-column>
                <el-table-column
                label="状态"
                min-width="120">
                <template #default="scope">
                    <span class="colblue ">{{ ['','待审核','绑定成功','审核失败'][scope.row.status]}}</span>
                </template>
                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="150">
                <template #default="scope">
                    <span v-if="scope.row.status==1">
                        <span @click="open(scope.row.bindingId,1)" class="colblue point">通过</span>
                        <el-divider direction="vertical"></el-divider>
                        <span @click="open(scope.row.bindingId,2)" class="colred point">拒绝</span>
                    </span>
                    <span v-else>
                        <span @click="open(scope.row.bindingId,3)" class="colblue point">删除用户</span>
                    </span>
                </template>
                </el-table-column>
            </el-table>
        </div>
    </div>

    <el-dialog title="分配CAU身份给其他人" width="490px" v-model="fenpei">
        <div class="padl20">
            <el-select  class="w350" v-model="accountId" placeholder="选择公司的其它同事">
                <el-option
                v-for="item in peopL"
                :key="item.accountId"
                :label="item.username"
                :value="item.accountId">
                </el-option>
            </el-select>
            <p class="w350 font10 colb2 lh20 padt10">选择将身份分配给其他人，必须从当前公司已绑定的用户列表中进行选择，分配后你的身份将成为普通用户</p>
        </div>
        <template #footer>
            <div class="tl padl20 padb10">
                <el-button size="small"  type="primary" @click="fenpeiSub" class="w90" >确认分配</el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog title="您确定要解除当前绑定的SCI账号么？" width="490px" v-model="jiebang">
        <div class="padl10">
            <p class="col65">解除绑定后 则不能提交该SCI号类型下的技术工单</p>
        </div>
        <template #footer>
            <div class="tl padl10 padb10">
                <el-button size="small" @click="jiebang = false" class="w68" >取 消</el-button>
                <el-button size="small"  type="primary" @click="jiebangSub" class="w68" >确 定</el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import { bindingCsiInfo, othersBindingCsi, distributionOthers, unbindCsi, bindingAcount } from "@/utils/api1.js"

export default {
    data(){
        return{
            id: this.$route.params.id,
            info:{
                personLists:[],
            },
            peopL:[],
            accountId:'',
            jiebang: false,
            fenpei:false,  //分配CAU身份给其他人
        }
    },
    mounted(){
        this.getData();
    },
    methods:{

        open(id,type) {
            let txt = ['','同意','拒绝','删除'][type];
            let title = '是否'+txt+'用户加入公司团队的申请？' ;
            let action = txt+'申请后也可以在公司团队中进行删除或者操作';
            if(type==3){
                title = '您确定要删除该用户吗？' ;
                action = '';
            }

            this.$confirm(action, title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                bindingAcount(id,type).then(res=>{
                    console.log(res);
                    if(res){
                        this.$message.success(res.message);
                        this.getData();
                    }
                })
            }).catch(() => {});
        },

        jiebangSub(){
            unbindCsi(this.id).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.$router.push('/account');
                };
                this.jiebang = false;
            })
        },

        fenpeiSub(){
            if(!this.accountId){
                this.$message.warning('请选择人员！');
                return
            }
            distributionOthers(this.id,this.accountId).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.getData();
                };
                this.fenpei = false;
            })
        },

        getData(){
            bindingCsiInfo(this.id).then(res=>{
                console.log(res);
                if(res){
                    this.info =res;
                }
            });

            othersBindingCsi(this.id).then(res=>{
                console.log(res);
                if(res){
                    this.peopL =res;
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.csi1{
    color: #272729;
    padding: 0 20px 35px;
    dd{
        padding-top: 32px;
        span:nth-child(1){
            color: #656565;
            width: 110px;
            min-width: 110px;
            text-align: right;
            padding-right: 60px;
            display: inline-block;
        }
    }
}
.w350{ width: 350px;}
</style>